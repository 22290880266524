import Mixpanel from 'mixpanel-browser'

import { User } from '@/types/user'

import { AnalyticsEventName, AnalyticsProvider } from '../AnalyticsManager'

export class MixpanelProvider implements AnalyticsProvider {
  private static initialized = false
  private static mixpanelToken?: string

  constructor(mixpanelToken?: string) {
    MixpanelProvider.mixpanelToken = mixpanelToken
  }

  initialize() {
    const mixpanelToken = MixpanelProvider.mixpanelToken
    if (!MixpanelProvider.initialized && mixpanelToken) {
      Mixpanel.init(mixpanelToken)
      MixpanelProvider.initialized = true
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(eventName: AnalyticsEventName, payload?: any) {
    if (!MixpanelProvider.initialized) return

    Mixpanel.track(eventName, payload)
  }

  pageView(page: string, payload?: any) {
    if (!MixpanelProvider.initialized) return

    Mixpanel.track(`${page} / Page / View`, payload)
  }

  setUserProperties(user: User) {
    if (!MixpanelProvider.initialized) return

    Mixpanel.identify(`${user.user_id}`)
    Mixpanel.register(user)
  }

  reset() {
    if (!MixpanelProvider.initialized) return

    Mixpanel.reset()
  }
}
