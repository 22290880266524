import styled from 'styled-components'

import { getToken } from '@/miranda'

import { Z_INDEX } from '@/styles/zindex'

const OneTimeDisclaimerWrapper = styled.div`
  background-color: ${getToken('color-neutral-white')};
  z-index: ${Z_INDEX.ONE_TIME_DISCLAIMER};
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: ${getToken('space-m')} ${getToken('space-xl')};
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
`

export { OneTimeDisclaimerWrapper }
