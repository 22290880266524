import { httpClient } from './httpClient'

type QueryParams = {
  [key: string]: string | number | boolean
}

const fetcher = async (url: string, queryParams: QueryParams = {}): Promise<JSON> => {
  return httpClient.get(url, { params: queryParams }).then(res => res.data)
}

export { fetcher }
