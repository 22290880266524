import * as Sentry from '@sentry/nextjs'
import axios, { AxiosError, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios'

import { forceUserLogout, getAccessToken, getRefreshToken, updateAccessToken } from '@/auth/token'
import { LOGIN_URL, REFRESH_TOKEN_URL } from '@/services/auth'
import { DATA_INSIGHTS_API } from '@/services/baseUrl'
import { isInShipperGuideIframe, requestShipperGuideToRefreshToken } from '@/utils/shipperGuide'

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
})

/** Interceptor to include Access Token on requests */
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getAccessToken()
    if (token && config.url?.includes(DATA_INSIGHTS_API)) {
      config = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: 'Bearer ' + token,
        } as AxiosRequestHeaders,
      }
    }
    return config
  },
  requestError => {
    return Promise.reject(requestError)
  }
)

type AxiosRequestConfigWithRetried = InternalAxiosRequestConfig & {
  _retried?: boolean
  _retries?: number
}

const shouldTryRefreshToken = (
  requestConfig: AxiosRequestConfigWithRetried,
  responseError: AxiosError<XMLHttpRequest>
) => {
  return (
    // The request is not a login request or a refresh token request
    requestConfig.url !== LOGIN_URL &&
    requestConfig.url !== REFRESH_TOKEN_URL &&
    // The request has a response
    responseError.response &&
    // The response status is 401 (unauthorized)
    responseError.response.status === 401 &&
    // The request hasn't already been retried
    !requestConfig._retried
  )
}

const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken()

  if (!refreshToken) throw new Error('No refresh token available')

  const response = await axiosInstance.post(REFRESH_TOKEN_URL, {
    refresh: refreshToken,
  })
  const { access: newAccessToken } = response.data
  updateAccessToken(newAccessToken)
}

/** Response interceptor to catch expired tokens request and request a new token with the Refresh token */
axiosInstance.interceptors.response.use(
  res => {
    return res
  },
  async (responseError: AxiosError<XMLHttpRequest>) => {
    const requestConfig = responseError.config as AxiosRequestConfigWithRetried

    if (shouldTryRefreshToken(requestConfig, responseError)) {
      if (isInShipperGuideIframe()) {
        if (requestConfig._retries && requestConfig._retries > 10) {
          // prevents infinite loop if one of our endpoints is correctly returning 401
          Sentry.captureException(
            `Failed to refresh token from ShipperGuide when trying to access ${requestConfig.url}`
          )
          return Promise.reject(responseError)
        }
        // If the user is in the Shipper Guide iframe, request shipper guide to refresh the token then resets the page
        requestShipperGuideToRefreshToken()
        await new Promise(resolve => setTimeout(resolve, 1000)) // waiting a while before retry
        requestConfig._retries = requestConfig._retries ? requestConfig._retries + 1 : 1 // counting retries
        return axiosInstance(requestConfig)
      } else {
        requestConfig._retried = true // Mark the request as retried
        try {
          // get a new refresh token and retry the original request
          await refreshAccessToken()
          return axiosInstance(requestConfig)
        } catch (refreshRequestError) {
          // Refresh token no longer valid, so force the user to log in again
          forceUserLogout()
          return Promise.reject(refreshRequestError)
        }
      }
    }
    // If we got here, we couldn't retry the original request
    Sentry.captureMessage(
      `Request to ${requestConfig.url} failed with status ${responseError.response?.status}`
    )
    return Promise.reject(responseError)
  }
)
const httpClient = axiosInstance

export { httpClient }
