import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import { fonts } from './fonts'
import { getToken } from './miranda'
import { print } from './print'
import { reset } from './reset'

export default createGlobalStyle`
  ${normalize}
  ${reset}
  ${fonts}

  @media print {
    ${print}
  }

  &::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background: ${getToken('color-neutral-lighter')};
  }
  &::-webkit-scrollbar-thumb {
    background: ${getToken('color-neutral-light')};
  }
`
