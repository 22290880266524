import { ReactNode, useEffect } from 'react'
import useLocalStorageState from 'use-local-storage-state'

import { Button, Layout } from '@/miranda'

import { useShipperGuide } from '@/hooks/useShipperGuide'

import { OneTimeDisclaimerWrapper } from './OneTimeDisclaimer.styles'

type OneTimeDisclaimerProps = {
  dismissButtonLabel?: string
  storageKey: string
  showOnShipperGuide?: boolean
  showOnLoadsmart?: boolean
  children?: ReactNode
}

const OneTimeDisclaimer = ({
  dismissButtonLabel = 'Ok',
  storageKey,
  showOnShipperGuide = false,
  showOnLoadsmart = true,
  children,
}: OneTimeDisclaimerProps) => {
  const { isShipperGuide } = useShipperGuide()

  const [oneTimeBannerDismissed, setOneTimeBannerDismissed] = useLocalStorageState<boolean>(
    storageKey,
    {
      defaultValue: undefined,
    }
  )

  useEffect(() => {
    if (!showOnShipperGuide && isShipperGuide) {
      // dismissed because it is shipperguide and it should not appear on shipperguide
      setOneTimeBannerDismissed(true)
    } else if (!showOnLoadsmart && !isShipperGuide) {
      // dismissed because it is loadsmart.com and it should not appear on loadsmart.com
      setOneTimeBannerDismissed(true)
    } else if (typeof oneTimeBannerDismissed === 'undefined') {
      // No criteria for hiding it meet, display banner
      setOneTimeBannerDismissed(false)
    }
  }, [
    setOneTimeBannerDismissed,
    oneTimeBannerDismissed,
    isShipperGuide,
    showOnShipperGuide,
    showOnLoadsmart,
  ])

  if (typeof oneTimeBannerDismissed === 'undefined' || oneTimeBannerDismissed) return null

  const dismissCookiesDisclaimer = () => {
    setOneTimeBannerDismissed(true)
  }

  return (
    <OneTimeDisclaimerWrapper>
      <Layout.Group align="center" justify="space-between">
        {children}

        <Button variant="primary" onClick={dismissCookiesDisclaimer}>
          {dismissButtonLabel}
        </Button>
      </Layout.Group>
    </OneTimeDisclaimerWrapper>
  )
}

export { OneTimeDisclaimer }
export type { OneTimeDisclaimerProps }
