import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import * as Sentry from '@sentry/nextjs'
import { CookieSetOptions } from 'universal-cookie'
import useLocalStorageState from 'use-local-storage-state'

import { AnalyticsManager } from '@/analytics/AnalyticsManager'
import { useEffectOnce } from '@/hooks/useEffectOnce'
import { useShipperGuide } from '@/hooks/useShipperGuide'
import { LOGIN_URL } from '@/services/auth'
import { httpClient } from '@/services/httpClient'
import {
  CredentialsEventPayload,
  DataInsightsMessageEvent,
  ShipperGuideUserData,
  ShipperLocationDict,
} from '@/types/shipperGuide'
import { User, UserAuthCredentials, UserResponse } from '@/types/user'
import { informShipperGuidePageIsReady } from '@/utils/shipperGuide'

const USER_COOKIE_NAME = 'user'
const USER_AUTH_CREDENTIALS_COOKIE_NAME = 'auth-credentials'
const SHIPPER_LOCATIONS_LOCAL_STORAGE_KEY = 'locations'

/**
 * following as many OWASP specs as possible https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/HTML5_Security_Cheat_Sheet.md#local-storage
 * secure: https access only
 * sameSite: only allow cookie access from the main website domain
 * Since Data Insights may be loaded into ShipperGuide as an iframe, it is classed as a third-party cookie
 * */
const COOKIE_OPTIONS: CookieSetOptions = {
  secure: ['test', 'development'].includes(process.env.NODE_ENV) ? false : true,
  sameSite: 'strict',
  path: '/',
}

const SHIPPER_GUIDE_CREDENTIALS_TIMEOUT = 4000

type AuthContextData = {
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  user: User
  isLoggedIn: boolean
  isLoading: boolean
  isBIPUser: boolean
  shipperGuideLocationId?: string
  locations?: ShipperLocationDict
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthProvider = (props: any) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    USER_COOKIE_NAME,
    USER_AUTH_CREDENTIALS_COOKIE_NAME,
  ])
  const [isLoadingCookies, setLoadingCookies] = useState(true)
  const { isShipperGuide } = useShipperGuide()
  const [waitingForSgCredentials, setWaitingForSgCredentials] = useState(() =>
    isShipperGuide ? true : false
  )
  const [shipperLocations, setShipperLocations] = useLocalStorageState<
    ShipperLocationDict | undefined
  >(SHIPPER_LOCATIONS_LOCAL_STORAGE_KEY)

  const user = cookies[USER_COOKIE_NAME] as User
  const isLoggedIn = Boolean(user?.email)
  const isLoading = isLoadingCookies || waitingForSgCredentials
  const shipperGuideLocationId =
    user?.active_location_id || Object.keys(shipperLocations || {})?.[0]
  const isBIPUser = user?.has_supervisor_user_in_shipper_network

  useEffectOnce(() => {
    // prevents static rendered pages from having hydration issues
    setLoadingCookies(false)
  })

  const login: AuthContextData['login'] = useCallback(
    async (email, password): Promise<void> => {
      const response = await httpClient.post(LOGIN_URL, {
        email,
        password,
      })
      const authenticatedUserResponse = response.data as UserResponse
      const { access, refresh, expires_after, ...userProperties } = authenticatedUserResponse
      const authCredentials: UserAuthCredentials = { access, refresh, expires_after }

      setCookie(USER_COOKIE_NAME, userProperties, COOKIE_OPTIONS)
      setCookie(USER_AUTH_CREDENTIALS_COOKIE_NAME, authCredentials, COOKIE_OPTIONS)
      AnalyticsManager.setUserProperties(userProperties)
    },
    [setCookie]
  )

  const logout: AuthContextData['logout'] = useCallback(() => {
    removeCookie(USER_COOKIE_NAME, COOKIE_OPTIONS)
    removeCookie(USER_AUTH_CREDENTIALS_COOKIE_NAME, COOKIE_OPTIONS)
    localStorage.removeItem(SHIPPER_LOCATIONS_LOCAL_STORAGE_KEY)
    return Promise.resolve()
  }, [removeCookie])

  const shipperGuideLogin = useCallback(
    async ({
      activeLocation,
      locations = {},
      shipperGuideUserData,
    }: {
      activeLocation?: string | null
      locations?: ShipperLocationDict
      shipperGuideUserData: ShipperGuideUserData
    }) => {
      const authCredentials: UserAuthCredentials = {
        access: shipperGuideUserData.accessToken,
        refresh: null,
        expires_after: null,
      }
      const userProperties: User = {
        // user related
        from_shipper_guide: true,
        email: shipperGuideUserData.email,
        roles: ['Shipper'],
        user_id: `SG_${shipperGuideUserData.id}`, // User IDs from shipper Guide to be represented as SG_X where X is user uuid in shipper guide
        is_supervisor: shipperGuideUserData.isSupervisor,
        is_managed_trans: shipperGuideUserData.isManagedTrans,
        has_supervisor_user_in_shipper_network: shipperGuideUserData.hasSupervisorInShipperNetwork,

        // shipper related
        shipper_id: shipperGuideUserData.shipperExternalUUID,
        shipper_name: shipperGuideUserData.shipperName,
        active_location_id: activeLocation,
      }
      setCookie(USER_AUTH_CREDENTIALS_COOKIE_NAME, authCredentials, COOKIE_OPTIONS)
      setCookie(USER_COOKIE_NAME, userProperties, COOKIE_OPTIONS)
      setShipperLocations(locations)

      AnalyticsManager.setUserProperties(userProperties)
    },
    [setCookie, setShipperLocations]
  )

  useEffect(() => {
    if (!isShipperGuide) return

    const handleMessage = (event: MessageEvent) => {
      const isFromShipperGuide =
        event.origin === process.env.NEXT_PUBLIC_SHIPPER_GUIDE_URL ||
        process.env.NODE_ENV === 'test'

      if (event.isTrusted && isFromShipperGuide) {
        const { type, payload } = event.data as DataInsightsMessageEvent<CredentialsEventPayload>

        if (type === 'credentials') {
          const { activeLocation, locations, userData } = payload

          shipperGuideLogin({
            activeLocation,
            locations,
            shipperGuideUserData: userData,
          }).then(() => {
            setWaitingForSgCredentials(false)
          })
        }
      }
    }
    window.addEventListener('message', handleMessage)

    let credentialsTimeout: ReturnType<typeof setTimeout>
    if (waitingForSgCredentials) {
      informShipperGuidePageIsReady()
      credentialsTimeout = setTimeout(() => {
        informShipperGuidePageIsReady() // in case of errors, request credentials again
        Sentry.captureException(
          new Error(
            `Timeout (${SHIPPER_GUIDE_CREDENTIALS_TIMEOUT}ms) when waiting for ShipperGuide Credentials`
          )
        )
      }, SHIPPER_GUIDE_CREDENTIALS_TIMEOUT)
    }

    return () => {
      window.removeEventListener('message', handleMessage)
      if (credentialsTimeout) clearTimeout(credentialsTimeout)
    }
  }, [waitingForSgCredentials, isShipperGuide, shipperGuideLogin])

  const authContextValue = useMemo(
    () =>
      ({
        user: user
          ? {
              ...user,
              ...(shipperLocations && { locations: shipperLocations }),
            }
          : undefined,
        isBIPUser,
        shipperGuideLocationId,
        isLoggedIn,
        isLoading,
        login,
        logout,
      } as AuthContextData),
    [
      user,
      shipperLocations,
      isBIPUser,
      shipperGuideLocationId,
      isLoggedIn,
      isLoading,
      login,
      logout,
    ]
  )

  return <AuthContext.Provider value={authContextValue} {...props} />
}

export {
  AuthContext,
  AuthProvider,
  COOKIE_OPTIONS,
  SHIPPER_LOCATIONS_LOCAL_STORAGE_KEY,
  USER_AUTH_CREDENTIALS_COOKIE_NAME,
  USER_COOKIE_NAME,
}
export type { AuthContextData }
