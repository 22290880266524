import styled from 'styled-components'

import { getToken } from '@/miranda'

const FullSizeCenteredDiv = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${getToken('space-l')};
`

export { FullSizeCenteredDiv }
