import { css } from 'styled-components'

export const print = css`
  * {
    -webkit-print-color-adjust: exact;
    box-shadow: none !important;
  }

  body {
    width: 100%;
    display: block;
    background-color: white;
    overflow: hidden;
  }

  table,
  img,
  svg,
  div,
  section {
    break-inside: avoid;
  }

  main {
    padding-top: 0;
    background-color: white;
    width: 1112px;
  }

  .hiddenOnPrint {
    display: none !important;
  }

  button {
    display: none;
  }

  iframe {
    display: none;
  }
`
