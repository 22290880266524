import { AnalyticsManager } from './AnalyticsManager'
import { HotjarProvider } from './hotjar/HotjarProvider'
import { MixpanelProvider } from './mixpanel/MixpanelProvider'

export const setupAnalyticsManager = () => {
  AnalyticsManager.init([
    new MixpanelProvider(process.env.NEXT_PUBLIC_MIXPANEL_KEY),
    new HotjarProvider(Number(process.env.NEXT_PUBLIC_HOTJAR_ID)),
  ])
}
