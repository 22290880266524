type Size = 'sm' | 'md' | 'lg' | 'xlg'

export const SCREEN_INT_SIZES: { [key in Size]: number } = {
  sm: 320,
  md: 768,
  lg: 1024,
  xlg: 1280,
}

export const SCREEN_SIZES = Object.keys(SCREEN_INT_SIZES).reduce(
  (result, sizeName) => ({
    ...result,
    [sizeName]: `${SCREEN_INT_SIZES[sizeName as Size]}px`,
  }),
  {}
) as { [key in Size]: string }

export const MEDIA_QUERY_SCREEN = Object.keys(SCREEN_INT_SIZES).reduce(
  (result, sizeName) => ({
    ...result,
    [sizeName]: `@media (min-width: ${SCREEN_SIZES[sizeName as Size]})`,
  }),
  {}
) as { [key in Size]: string }
