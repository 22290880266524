import { useState } from 'react'
import { useCustomEventListener } from 'react-custom-events'

import { Toast, ToastProps } from '@/miranda'

import { EVENTS } from '@/utils/events'

import { ToastContainer } from './ToastWatcher.styles'

const ToastWatcher = () => {
  const [open, setOpen] = useState(false)
  const [config, setConfig] = useState<ToastProps>()

  useCustomEventListener(EVENTS.OPEN_TOAST, (newToastConfig: ToastProps) => {
    setConfig(newToastConfig)
    setOpen(true)
  })

  const handleClose = () => {
    config?.onClose?.()
    setConfig(undefined)
    setOpen(false)
  }

  if (!open) return null

  return (
    <ToastContainer>
      <Toast message="" {...config} onClose={handleClose} />
    </ToastContainer>
  )
}

export { ToastWatcher }
