import { css } from 'styled-components'

export const reset = css`
  html {
    box-sizing: border-box;
  }

  body {
    background-color: white;
  }

  dl,
  menu,
  ol,
  fieldset,
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  figure,
  p {
    margin: 0;
  }

  button:focus {
    outline: 0;
  }

  a {
    text-decoration: none;
    box-sizing: border-box;
  }

  input[type='text'],
  input[type='email'],
  input[type='search'],
  input[type='password'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  select {
    text-indent: 0.01px;
    text-overflow: '';

    -moz-appearance: none;
  }

  input::-ms-clear {
    display: none;
  }

  div {
    box-sizing: border-box;
  }
`
