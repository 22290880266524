import styled from 'styled-components'

import { getToken } from '@/miranda'

import { Z_INDEX } from '@/styles/zindex'

const ToastContainer = styled.div`
  @keyframes slide-in {
    from {
      transform: translateX(50px);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
  }

  position: fixed;
  top: ${getToken('space-l')};
  right: ${getToken('space-l')};
  z-index: ${Z_INDEX.TOAST};
  will-change: transform;
  animation: slide-in 0.3s ease, fade-in 0.3s ease;
`

export { ToastContainer }
