// map all z-indexes in the app to make it easier to manage what stays on top
const Z_INDEX = {
  CHART_OVERLAY: 5,
  NAVBAR: 10,
  REPORT_HEADER: 10,
  ONE_TIME_DISCLAIMER: 10000,
  TOAST: 10000,
}

export { Z_INDEX }
