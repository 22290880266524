import Cookies from 'universal-cookie'

import {
  COOKIE_OPTIONS,
  SHIPPER_LOCATIONS_LOCAL_STORAGE_KEY,
  USER_AUTH_CREDENTIALS_COOKIE_NAME,
  USER_COOKIE_NAME,
} from '@/auth/AuthProvider'
import { UserAuthCredentials } from '@/types/user'

const cookies = new Cookies()

const getAccessToken = () => {
  const authCredentials = cookies.get(USER_AUTH_CREDENTIALS_COOKIE_NAME) as UserAuthCredentials
  return authCredentials?.access
}

const getRefreshToken = () => {
  const authCredentials = cookies.get(USER_AUTH_CREDENTIALS_COOKIE_NAME) as UserAuthCredentials
  return authCredentials?.refresh
}

const updateAccessToken = (newAccessToken: string) => {
  const authCredentials = cookies.get(USER_AUTH_CREDENTIALS_COOKIE_NAME) as UserAuthCredentials
  authCredentials.access = newAccessToken
  cookies.set(USER_AUTH_CREDENTIALS_COOKIE_NAME, authCredentials, COOKIE_OPTIONS)
}

const removeAccessToken = () => {
  cookies.remove(USER_AUTH_CREDENTIALS_COOKIE_NAME, COOKIE_OPTIONS)
  cookies.remove(USER_COOKIE_NAME, COOKIE_OPTIONS)
  localStorage.removeItem(SHIPPER_LOCATIONS_LOCAL_STORAGE_KEY)
}

const forceUserLogout = () => {
  removeAccessToken()
  window.location.href = '/'
}

export { forceUserLogout, getAccessToken, getRefreshToken, removeAccessToken, updateAccessToken }
