import { useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import { SWRConfig } from 'swr'

import { THEME } from '@/miranda'

import { GoogleAnalyticsLoader } from '@/analytics/googleAnalytics/GoogleAnalyticsLoader'
import { setupAnalyticsManager } from '@/analytics/setupAnalyticsManager'
import { AuthProvider } from '@/auth/AuthProvider'
import { LoadingPage } from '@/components/layout/LoadingPage/LoadingPage'
import { PageContainer } from '@/components/layout/Wrappers/FullPageContainer'
import { ToastWatcher } from '@/components/Toast/ToastWatcher'
import { useEffectOnce } from '@/hooks/useEffectOnce'
import { fetcher } from '@/services/fetcher'
import GlobalStyle from '@/styles/global'

import { CookiesDisclaimer } from './components/CookiesDisclaimer/CookiesDisclaimer'

const SHOULD_ENABLE_REQUESTS_MOCKS =
  process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'local' && !process.env.NEXT_PUBLIC_FORCE_BACKEND

if (SHOULD_ENABLE_REQUESTS_MOCKS) {
  require('../__tests__/mocks')
}

export default function MyApp({ Component, pageProps }: AppProps) {
  const [analyticsLoading, setAnalyticsLoading] = useState(true)

  useEffectOnce(() => {
    try {
      setupAnalyticsManager()
    } catch (err) {
      Sentry.captureException(err)
    } finally {
      setAnalyticsLoading(false)
    }
  })

  /** ensure analytics are initialized before events
   *  start being triggered by pages */
  if (analyticsLoading)
    return (
      <ThemeProvider theme={THEME}>
        <PageContainer>
          <LoadingPage />
        </PageContainer>
      </ThemeProvider>
    )

  return (
    <ThemeProvider theme={THEME}>
      <SWRConfig
        value={{
          fetcher,
          revalidateOnFocus: false,
        }}
      >
        <AuthProvider>
          <Head>
            <title>Data Insights</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          </Head>
          <GoogleAnalyticsLoader googleAnalyticsID={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID} />
          <GlobalStyle />
          <Component {...pageProps} />
          <CookiesDisclaimer />
          <ToastWatcher />
        </AuthProvider>
      </SWRConfig>
    </ThemeProvider>
  )
}
