import styled from 'styled-components'

const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
`

export { PageContainer }
