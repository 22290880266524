import { hotjar } from 'react-hotjar'

import { User } from '@/types/user'

import { AnalyticsEventName, AnalyticsProvider } from '../AnalyticsManager'

export class HotjarProvider implements AnalyticsProvider {
  private static initialized = false
  private static hotjarId?: number
  private static hotjarServiceVersion = 6

  constructor(hotjarId?: number) {
    HotjarProvider.hotjarId = hotjarId
  }

  initialize() {
    const hotjarId = HotjarProvider.hotjarId
    if (!HotjarProvider.initialized && hotjarId) {
      hotjar.initialize(hotjarId, HotjarProvider.hotjarServiceVersion)
      HotjarProvider.initialized = true
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  track(eventName: AnalyticsEventName, payload?: any) {
    // hotjar does not accept event properties. typescript however complains if this fn is called
    // (by AnalyticsManager) with more than one parameter as specified in the interface.
    // So we have the `payload` parameter here even though we don't use it.
    if (!HotjarProvider.initialized) return

    hotjar.event(eventName)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  pageView(page: string, payload?: any) {
    // hotjar doesn't need page view events, as it tracks them automatically
    // https://help.hotjar.com/hc/en-us/articles/115011805428
    // still, we will submit events using our naming scheme to make searching on hotjar easier

    if (!HotjarProvider.initialized) return

    hotjar.event(`${page} / Page / View`)
  }

  setUserProperties(user: User) {
    if (!HotjarProvider.initialized) return

    hotjar.identify(`${user.user_id}`, user)
  }

  reset() {
    // hotjar doesn't allow us to untag a user after it has been identified. i.e. on logout
    // https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#user-attribute-values
  }
}
