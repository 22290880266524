import {
  IconArrowLeft,
  IconArrowRight,
  IconArrowsExpandSide,
  IconArrowsReverse,
  IconArtificialIntelligence,
  IconAttention,
  IconBell,
  IconBellCancel,
  IconCheck,
  IconClose,
  IconCopy,
  IconCreateValue,
  IconDevelopmentPlans,
  IconDislike,
  IconDotsHorizontal,
  IconDownload,
  IconExport,
  IconEye,
  IconFast,
  IconInfoCircle,
  IconLike,
  IconPencil,
  IconPlus,
  IconPrint,
  IconRetry,
  IconSearch,
  IconSend,
  IconSettings,
  IconTMSReady,
  IconTrash,
  IconUser,
  IconVisibility,
} from '@loadsmart/icons'
import { IconFactory } from '@loadsmart/loadsmart-ui'

const icons = {
  'arrow-left': IconArrowLeft,
  'arrows-reverse': IconArrowsReverse,
  'arrow-right': IconArrowRight,
  'arrows-expand-side': IconArrowsExpandSide,
  'artificial-intelligence': IconArtificialIntelligence,
  'bell-cancel': IconBellCancel,
  'create-value': IconCreateValue,
  'development-plans': IconDevelopmentPlans,
  'info-circle': IconInfoCircle,
  'tms-ready': IconTMSReady,
  attention: IconAttention,
  bell: IconBell,
  check: IconCheck,
  close: IconClose,
  copy: IconCopy,
  dislike: IconDislike,
  download: IconDownload,
  export: IconExport,
  eye: IconEye,
  fast: IconFast,
  like: IconLike,
  pencil: IconPencil,
  plus: IconPlus,
  print: IconPrint,
  retry: IconRetry,
  search: IconSearch,
  send: IconSend,
  settings: IconSettings,
  trash: IconTrash,
  user: IconUser,
  visibility: IconVisibility,
  'dots-horizontal': IconDotsHorizontal,
} as const

const Icon = IconFactory(icons)

export { Icon, icons }
