import styled from 'styled-components'

import { Icon, Layout, RegularLink, Text } from '@/miranda'

import { OneTimeDisclaimer } from '@/components/layout/OneTimeDisclaimer/OneTimeDisclaimer'

const COOKIES_BANNER_DISMISSED_STORAGE_KEY = 'cookies-banner'

const StyledLink = styled(RegularLink)`
  font-size: inherit;
  font-weight: inherit;
`

const CookiesDisclaimer = () => {
  return (
    <OneTimeDisclaimer storageKey={COOKIES_BANNER_DISMISSED_STORAGE_KEY} showOnShipperGuide={false}>
      <Layout.Group align="center" justify="flex-start">
        <Icon name="info-circle" size="20" />
        <Text variant="body-bold">
          We use cookies to improve your experience on our products. You can learn more&nbsp;
          <StyledLink href="https://loadsmart.com/privacy-policy/" target="_blank">
            in our privacy policy.
          </StyledLink>
        </Text>
      </Layout.Group>
    </OneTimeDisclaimer>
  )
}

export { CookiesDisclaimer }
