import * as Sentry from '@sentry/nextjs'

import { DataInsightsMessageEvent, StatusEventPayload } from '@/types/shipperGuide'

const isInShipperGuideIframe = () => {
  if (typeof window === 'undefined') return false

  try {
    const isInIframe = window.self !== window.top

    // if in an iframe, document.referrer contains the parent domain
    const windowUrl = document.referrer

    return (
      isInIframe && (windowUrl.includes('shipper.guide') || process.env.NODE_ENV === 'development')
    )
  } catch (err) {
    Sentry.captureException(err)
    return false
  }
}

const informShipperGuidePageIsReady = () => {
  window.parent.postMessage(
    {
      source: 'data-insights',
      type: 'status',
      payload: { status: 'ready' },
    } as DataInsightsMessageEvent<StatusEventPayload>,
    process.env.NODE_ENV === 'test' ? '*' : process.env.NEXT_PUBLIC_SHIPPER_GUIDE_URL
  )
}

const requestShipperGuideToRefreshToken = () => {
  window.parent.postMessage(
    {
      source: 'data-insights',
      type: 'refresh-token',
    } as DataInsightsMessageEvent,
    process.env.NODE_ENV === 'test' ? '*' : process.env.NEXT_PUBLIC_SHIPPER_GUIDE_URL
  )
}

export { informShipperGuidePageIsReady, isInShipperGuideIframe, requestShipperGuideToRefreshToken }
