import React from 'react'

/**
 * Runs an Effect only once. Useful for effects such as Analytics
 * calls that should run only once per page.
 *
 * Avoid running effects more than once on mount / unmount
 * https://reactjs.org/blog/2022/03/29/react-v18.html#new-strict-mode-behaviors
 * https://github.com/reactwg/react-18/discussions/18
 *
 * @param effectCallback React useEffect callback
 */
const useEffectOnce = (effectCallback: React.EffectCallback) => {
  const didRunRef = React.useRef(false)

  React.useEffect(() => {
    // In this case, whether we are mounting or remounting,
    // we use a ref so that we only log an impression once.
    if (didRunRef.current === false) {
      didRunRef.current = true

      return effectCallback()
    }
  }, [])
}

export { useEffectOnce }
