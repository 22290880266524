import { Spinner } from '@/miranda'

import { FullSizeCenteredDiv } from '../Wrappers/FullSizeCenteredDiv'

const LoadingPage = () => (
  <FullSizeCenteredDiv>
    <Spinner size={96} title="Loading" />
  </FullSizeCenteredDiv>
)

export { LoadingPage }
