const SHOULD_LOG_ANALYTICS =
  process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'local'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logAnalytics = (...logParams: any[]) => {
  if (SHOULD_LOG_ANALYTICS) {
    console.info(...logParams)
  }
}

export { logAnalytics }
