import React from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'

type GoogleAnalyticsLoaderProps = {
  googleAnalyticsID: string
}

function GoogleAnalyticsLoader({ googleAnalyticsID }: GoogleAnalyticsLoaderProps) {
  const router = useRouter()

  React.useEffect(() => {
    if (!googleAnalyticsID) return

    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    const handleRouteChange = (url: URL) => {
      window?.gtag?.('config', googleAnalyticsID, {
        page_path: url,
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return googleAnalyticsID ? (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsID}`}
      />
      <Script
        id="gaLoader"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleAnalyticsID}', {
            page_path: window.location.pathname,
            send_page_view: false,
          });
          `,
        }}
      />
    </>
  ) : null
}

export { GoogleAnalyticsLoader }
