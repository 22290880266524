import Router from 'next/router'
import styled, { createGlobalStyle } from 'styled-components'

import {
  Button as RegularButton,
  Card as RegularCard,
  Dialog as RegularDialog,
  DialogProps,
  Drawer as RegularDrawer,
  DrawerProps,
  Label as RegularLabel,
  Layout,
  Link as RegularLink,
  LinkProps,
  Tabs as RegularTabs,
} from '@loadsmart/loadsmart-ui'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import { toCSSValue } from '@loadsmart/miranda-tokens'

import { MEDIA_QUERY_SCREEN } from './screen'

export { Icon, icons } from './icons'
export * from '@loadsmart/loadsmart-ui'
export { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
export type { ColorToken } from '@loadsmart/miranda-tokens'
export { toCSSValue } from '@loadsmart/miranda-tokens'

/** Storybook is unable to see this enum from Miranda
 * https://github.com/loadsmart/miranda/blob/61542cde8196648c5435d05374c1a1ce99d7d7ae/src/utils/types/Status.ts#L1
 * Adding Status and as an declared export on Miranda fixes this,
 * but it shouldn't be necessary as other JS/TS projects works just fine.
 *
 * Storybook can't see `*.d.ts` files
 * https://github.com/storybookjs/storybook/issues/8961
 *
 * TODO: It seems like we have to replace Storybook compiler and fully configure typescript for it to work.
 */
export enum Status {
  Success = 'success',
  Danger = 'danger',
  Warn = 'warn',
  Neutral = 'neutral',
}

// https://github.com/vercel/next.js/issues/7915
// https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-functional-component
const Link = ({ href, ...props }: LinkProps) => (
  <RegularLink
    href={href}
    onClick={event => {
      event?.preventDefault()
      if (href) Router.push(href)
    }}
    {...props}
  />
)

const NoWrapGroup = styled(Layout.Group)`
  flex-wrap: nowrap;
`

const PreventBodyScrollStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

// Fix default Miranda Dialog and Drawer component allowing Body scroll
// We do not fix this on Miranda library as it may introduce bugs on other projects
const Dialog = (dialogProps: DialogProps) => (
  <>
    {dialogProps.open && <PreventBodyScrollStyle />}
    <RegularDialog {...dialogProps} />
  </>
)
// expose Dialog subcomponents
Dialog.Header = RegularDialog.Header
Dialog.Body = RegularDialog.Body
Dialog.Close = RegularDialog.Close
Dialog.ActionConfirm = RegularDialog.ActionConfirm
Dialog.ActionCancel = RegularDialog.ActionCancel
Dialog.Actions = RegularDialog.Actions

const StyledDrawer = styled(RegularDrawer)`
  & > button {
    background-color: transparent;
    top: ${toCSSValue('spacing-6')};
    right: ${toCSSValue('spacing-6')};
    left: auto;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  & {
    border-radius: 0;
  }

  min-width: 100%;

  ${MEDIA_QUERY_SCREEN.md} {
    min-width: 600px;
  }
`
const Drawer = (drawerProps: DrawerProps) => (
  <>
    {drawerProps.open && <PreventBodyScrollStyle />}
    <StyledDrawer {...drawerProps} />
  </>
)
Drawer.Header = styled(StyledDrawer.Header)`
  padding: ${toCSSValue('spacing-6')};
  justify-content: flex-start;
  border-bottom: none;
`
Drawer.Body = styled(StyledDrawer.Body)`
  padding: 0 ${toCSSValue('spacing-6')};
`
Drawer.Footer = styled(StyledDrawer.Footer)`
  padding: ${toCSSValue('spacing-6')};
  justify-content: flex-end;
  border-top: none;
`

const THEME = Themes.Miranda

const Button = styled(RegularButton)`
  text-transform: none;
`

const Tabs = RegularTabs
Tabs.Item = styled(RegularTabs.Item)`
  text-transform: none;
  font-weight: 500;
`

const Label = styled(RegularLabel)`
  span {
    color: ${toCSSValue('color-neutral-80')};
  }
`

const Card = styled(RegularCard)`
  > div {
    max-width: 100%; // prevents overflow and allows horizontal scroll on mobile
  }
`

export { Button, Card, Dialog, Drawer, Label, Link, NoWrapGroup, RegularLink, Tabs, THEME }
