import * as Sentry from '@sentry/nextjs'
import { Severity } from '@sentry/nextjs'

import { User } from '@/types/user'

import { logAnalytics } from './logAnalytics'
/** Event name should follow the pattern `<Context> / <Action>`:
 *
 * - `<Context>` can be a Page or Component using as many hierarchy
 * levels as needed separated by `/`. i.e.: `Home Page / Header`,
 * `Report Page / Cost Chart`, `Navbar`.
 *
 * - `<Action>` The user action or interaction we want to track
 * with Analytics. i.e.: `Login`, `Play`, `Expand`.
 */
type AnalyticsEventName = `${string} / ${string}`

interface AnalyticsProvider {
  initialize(): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track(eventName: AnalyticsEventName, payload?: any): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageView(page: string, payload?: any): void
  setUserProperties(user: User): void
  reset(): void
}

const ANALYTICS_DISABLED_USER_EMAIL_LIST = ['synthetic@test.com', 'synthetic.sg@test.com']

class AnalyticsManager {
  private static analyticsProviders: AnalyticsProvider[] = []
  private static initialized = false
  private static disabled = false

  static async init(providers: AnalyticsProvider[]) {
    AnalyticsManager.analyticsProviders = providers
    AnalyticsManager.initialize()
  }

  private static initialize() {
    AnalyticsManager.analyticsProviders.forEach(provider => {
      provider.initialize()
    })
    AnalyticsManager.initialized = true
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static track(eventName: AnalyticsEventName, payload?: any) {
    if (AnalyticsManager.disabled) return

    logAnalytics('Tracking Event:', {
      eventName,
      payload: payload || '',
    })
    AnalyticsManager.analyticsProviders.forEach(provider => {
      provider.track(eventName, payload)
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static pageView(page: string, payload?: any) {
    if (AnalyticsManager.disabled) return

    logAnalytics('Tracking Page View:', {
      page,
      payload: payload || '',
    })
    AnalyticsManager.analyticsProviders.forEach(provider => {
      provider.pageView(page, payload)
    })
  }

  static setUserProperties(user: User) {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'Authenticated user ' + user.email,
      level: Severity.Info,
    })
    Sentry.setUser(user)

    if (ANALYTICS_DISABLED_USER_EMAIL_LIST.includes(user.email)) {
      AnalyticsManager.disabled = true
      return
    }

    logAnalytics('Tracking User:', { user })
    AnalyticsManager.analyticsProviders.forEach(provider => {
      provider.setUserProperties(user)
    })
  }

  static reset() {
    AnalyticsManager.analyticsProviders.forEach(provider => {
      provider.reset()
    })
  }
}

export { AnalyticsManager }
export type { AnalyticsEventName, AnalyticsProvider }
