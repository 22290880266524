import { isNodeProcess } from 'is-node-process'

const initMocks = async () => {
  if (isNodeProcess()) {
    const { server } = await import('./server')
    server.listen()
  } else {
    const { worker } = await import('./browser')
    worker.start()
  }
}

initMocks()

export {}
